/**
 * ReactComponent Section
 * @param content - ReactComponent or list af ReactComponent
 * @param addOnCss - css overload
 * @returns {JSX.Element}
 * @constructor
 */
export default function Section({ children, addOnCss}) {
    return (<section className={`px-3xl md:px-xl
                py-5xl md:py-xl ${addOnCss}`}>
        {children}
    </section>);
}
