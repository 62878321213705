import {useState} from "react";

export default function Tel({ name, placeholder, handleChange, required = false, width = 'full'}) {
    const [value, setValue] = useState('');
    width = 'full' ? 'w-full' : width;

    function onChange (e) {
        setValue(e.target.value);
        handleChange(name, e.target.value);
    }

    return (
        <input type="tel" name={name}
               required={required}
               placeholder={placeholder + (required ? ' * ' : '')}
               className={`bg-transparent
                 border-solid border-b-2 border-dark-vanilla
                 py-md
                 focus-visible:border-t-0 focus-visible:border-x-0  
                 focus:border-t-0 focus:border-x-0
                 text-lg
                 ${width}`}
               onChange={onChange}
        />
    );
};
