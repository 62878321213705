import useFirstRender from "../../lib/reactExtends";
import {useEffect, useRef, useState} from "react";

function getClassNameMutedIcon(isMuted) {
    return isMuted ?  'icon-volume-off' : 'icon-volume-up'
}

export default function Video({ src, mimeType, autoPlay = false, muted = false, poster, children}) {
    const video = useRef(null);
    const [isMuted, setIsMuted] = useState(muted);
    const [volumeIcon, setVolumeIcon] = useState(getClassNameMutedIcon(muted));
    const isFirstRender = useFirstRender();

    useEffect(() => {
        if(isFirstRender) {
            if(!navigator.getAutoplayPolicy || navigator.getAutoplayPolicy(video.current) !== "allowed")
                setIsMuted(true);
        }
        setVolumeIcon(getClassNameMutedIcon(isMuted))
        video.current.autoplay = autoPlay;
        video.current.muted = isMuted;

        // Will whenever any item in dependency array changes
    }, [isFirstRender, autoPlay, isMuted, video.current]);

    function updateMutedStatuses() {
        // mute all other video if unmute current
        if(isMuted) {
            document.querySelector('video').muted = true;
        }
        setIsMuted(!isMuted);
    }

    function toggleControls() {
        // mute all other video if unmute current
        const video = document.querySelector('video')
        video.controls = !video.controls;
    }

    return (
        <div className="group/video relative ">
            <video muted={isMuted} loop className={`rounded - [3px] w-full`} ref={video} poster={poster}>
                <source src={src} type={mimeType}/>
                <p>
                    Your navigator can't read video in html5. Here is the
                    <a href={src}> link to download the
                        video</a>.
                </p>
            </video>
            <div className="absolute right-0 top-0 text-dark-vanilla text-4xl flex">
                <div className={`
                    cursor-pointer
                    pt-xl pr-sm
                    hidden md:block
                    group-hover/video:block
                    icon-menu`}
                     onClick={toggleControls} title="Display controls">
                </div>
                <div className={`
                    cursor-pointer
                    pt-xl pr-sm
                    ${volumeIcon}`}
                     title={isMuted ? 'Unmute' : 'Mute'}
                     onClick={updateMutedStatuses}>
                </div>
            </div>

        </div>
    );
}
