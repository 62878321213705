import { useState } from 'react';
import {suluImg} from "../../../lib/sulu";

function NftButtons() {
    const actions = [
        {
            label: "merge",
            url: "",
        },
        {
            label: "sell",
            url: "",
        },
        {
            label: "lease",
            url: "",
        },
        {
            label: "buy",
            url: "",
        },
        {
            label: "auction",
            url: "",
        },
    ]

    return (
        <div className="flex flex-col md:flex-row md:flex-wrap md:gap-[12px] text-left md:justify-center text-[1.12rem]">
            {actions.map(action => <a href={action.url} className="uppercase text-dark-vanilla">{action.label}</a>)}
        </div>
    );
}


function NftCard({ nft }) {
    return (
    <article className="flex gap-[12px] flex-shrink md:flex-col md:w-full md:justify-center mx-auto">
        <div className="w-[40vw] md:w-full max-w-[620px] md:max-w-full h-[28vw] md:h-[35vw] max-h-[270px] relative">
            <div className="absolute w-full h-full rounded-[18px]">
                <img className="pointer-events-none w-full h-full object-cover rounded-[18px] object-[0%_0%]"
                    src={suluImg(nft.nftImage)} alt={nft.nftImage?.title || '' } />
                <div className="absolute w-full h-full rounded-[18px] bg-gradient-to-tl from-eerie-black to-transparent top-0"></div>
            </div>

            <div className="z-[1] absolute bottom-[8%] w-[20%] md:min-w-[75px] right-[2%] text-left text-sm text-dark-vanilla">
                <div className="">{nft.nftName}</div>
                <div>{nft.nftPurchaseDate}</div>
                <div>{nft.nftAuthor}</div>
            </div>
        </div>
        {/*<NftButtons />*/}
    </article>
    );
}

export default function NftList({ nfts }) {
    return (
        <>
            {nfts.map((nft) => <NftCard nft={nft}/>)}
        </>
    );
}
