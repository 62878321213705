export default function Submit({ children, addOnCss, onClick }) {
    return (<button type="submit"
                   className={`bg-dark-vanilla text-white
                    border-solid border-b-2 border-white
                    rounded-3xl
                    py-sm
                    px-md
                    uppercase min-w-[200px]
                    text-lg
                    ${addOnCss}`}
                    onClick={onClick}
    >{children}</button>);
};
