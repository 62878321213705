import Section from "../components/section";
import Title from "../components/title";
import NftList from "../components/nft/nftList";
import Carousel from "../components/carousel";
import { useTranslation } from "react-i18next";
import { suluImg } from "../lib/sulu";
import useFirstRender from "../lib/reactExtends";
import { useEffect, useState } from "react";
import Video from "../components/video";


/**
 *
 * @param radius - circle radius
 * @param CX - circle center horizontal position
 * @param CY - circle center vertical position
 */
function getCircleProperty({ radius, CX, CY }) {
    return `m ${CX - radius}, ${CY} a ${radius}, ${radius} 0 1, 0 ${radius * 2}, 0 a ${radius}, ${radius} 0 1, 0 ${- (radius * 2)}, 0`;
}

const circles = {
    outerCircle: {
        radius: 39,
        CX: 50,
        CY: 50
    },
    innerCircle: {
        radius: 30,
        CX: 50,
        CY: 50
    },
    filledCircle: {
        radius: 27,
        CX: 50,
        CY: 50
    },
}




/**
 * ReactComponent Home
 *  contain the definition of the page content
 * @param pageResponse - ReactComponent or list af ReactComponent
 * @param addOnCss - css overload
 * @returns {JSX.Element}
 * @constructor
 */
export default function Home({pageResponse, children }) {
    const { t } = useTranslation();

    const pageContent = pageResponse.data.content;

    const founders = pageContent.founders?.map((founder) => {
        return {
            'label': founder.name,
            'subLabel': founder.job,
            'description': founder.description,
            'img': suluImg(founder.image)
        }
    })
    const teammates = pageContent.teammates?.map((mate) => {
        return {
            'label': mate.name,
            'subLabel': mate.job,
            'description': mate.description,
            'img': suluImg(mate.image)
        }
    })

    return (
    <>
        { Object.keys(pageContent).length === 0 ? (<div>empty content</div>) : (<>
            <Section addOnCss="pl-0 pr-0 pt-0
                           md:pl-0 md:pr-0 md:pt-0 pb-0
                           w-full ">
                <div>

                    <Video src={process.env.REACT_APP_API_PATH + pageContent.introductionVideo?.url}
                           poster={suluImg(pageContent.introductionPoster)}
                           mimeType={pageContent.foundationVideo?.mimeType}
                           autoPlay
                           muted
                    />
                </div>
            </Section>
            <Section addOnCss="">
                <div className="flex flex-wrap">
                    <Title addOnCss="flex-shrink w-[35%] md:w-full" isHTML={true}>{pageContent.foundationTitle}</Title>
                    <div className="text-dark-vanilla
                                    w-[75%] md:w-full
                                    text-left md:text-justify sm:text-justify
                                    md:text-lg
                                    px-2xl md:px-0"
                         dangerouslySetInnerHTML={{"__html": pageContent.foundationDescription}}>
                    </div>
                </div>
                <div>
                    <Video autoPlay loop muted className="rounded-[3px]"
                           poster={suluImg(pageContent.foundationPoster)}
                           src={process.env.REACT_APP_API_PATH + pageContent.foundationVideo?.url}
                           mimeType={pageContent.foundationVideo?.mimeType}
                    ></Video>
                </div>
            </Section>
            <Section addOnCss="flex flex-wrap md:flex-col-reverse bg-[#020417] !pb-[min(250px,35vw/2)]">
                <div className="text-dark-vanilla
                             w-[50%] md:w-full
                             text-right md:text-justify sm:text-justify
                             md:text-lg
                             px-2xl md:px-0"
                     dangerouslySetInnerHTML={{"__html": pageContent.cfPlatformDescription}}></div>
                <Title addOnCss="flex-shrink w-[50%] md:w-full" isHTML={true}>{pageContent.cfPlatformTitle}</Title>
            </Section>

            <div className="-mt-[min(250px,35vw/1.75)] flex">
                <div className="w-1/2">
                    <div className="bg-[url('../public/images/ampersand.png')] bg-cover bg-no-repeat
                                    w-[10vw] h-[calc(10vw*1.5)]
                                    ml-auto mr-[50%]"></div>
                </div>
                <div className="w-1/2">
                    <div className="giving-back-ring w-[35vw] max-w-[400px] max-h-[400px] h-[35vwvw] relative mx-auto">
                        <svg id="rotatingText" className="w-[35vw] max-w-[400px] max-h-[400px] h-[35vwvw]"
                             viewBox="0 0 100 100">
                            <defs>
                                <path id="circle" d={getCircleProperty(circles.outerCircle)}></path>
                                <path id="inner-circle" d={getCircleProperty(circles.innerCircle)}></path>
                            </defs>
                            <circle cx={circles.filledCircle.CX} cy={circles.filledCircle.CY}
                                    r={circles.filledCircle.radius}
                                    fill="#DDC4A7"/>
                            <text className="medium fill-white" fontFamily="CopperplateBold">
                                <textPath alignmentBaseline="top" xlinkHref="#circle"
                                          className="text uppercase text-[8.75px]" side="right">
                                    let's make sustainability profitable for all.
                                </textPath>
                            </text>
                            <text className="small fill-white" fontFamily="CopperplateBold">
                                <textPath alignmentBaseline="top" xlinkHref="#inner-circle"
                                          className="text uppercase text-[7.5px] small" side="right">
                                    A video game to reclaim our humanity.
                                </textPath>
                            </text>
                            <text className="fill-white text-[8px] uppercase" textAnchor="middle" x="50" y="52.5">
                                Giving back
                            </text>
                        </svg>
                    </div>
                </div>
            </div>
            <Section addOnCss="flex flex-wrap">
                <Title addOnCss="flex-shrink
                                 w-[50%] md:w-full
                                 text-right md:text-left"
                       isHTML={true}>
                    {pageContent.videogameTitle}
                </Title>
                <div className="text-dark-vanilla
                                w-[50%] md:w-full
                                text-left md:text-justify sm:text-justify
                                md:text-lg
                                px-2xl md:px-0"
                     dangerouslySetInnerHTML={{"__html": pageContent.videogameDescription}}></div>
            </Section>

            <Section addOnCss="bg-black-olive">
                <div className="flex flex-wrap align-middle">
                    <div className="flex flex-wrap w-[50%] md:w-full">
                        <Title addOnCss="flex-shrink
                                     text-right md:text-justify sm:text-justify"
                               isHTML={true}
                        >
                            {pageContent.nftDashboardTitle}
                        </Title>
                        <div className="text-dark-vanilla
                                            text-right md:text-justify sm:text-justify
                                            md:text-lg
                                            px-2xl md:px-0"
                             dangerouslySetInnerHTML={{"__html": pageContent.nftDashboardDescription}}></div>
                    </div>
                    <div className="text-dark-vanilla w-[50%] md:w-full sm:w-[100vw]
                                px-2xl md:px-0 sm:px-0
                                align-middle">
                        <Video src={process.env.REACT_APP_API_PATH + pageContent.nftDashboardVideo?.url}
                               poster={suluImg(pageContent.nftDashboardPoster)}
                               className="my-auto"
                               title="NFT - minting" loop={true} autoPlay={true}/>
                    </div>
                </div>
            </Section>

            <Section addOnCss="bg-black-olive md:flex-col-reverse flex">
                <div className="text-dark-vanilla w-[50%] md:w-full
                                        text-right md:text-justify sm:text-justify
                                        md:text-lg
                                        px-2xl md:px-0"
                     dangerouslySetInnerHTML={{"__html": pageContent.nftManagerDescription}}></div>
                <Title addOnCss="flex-shrink w-[50%] md:w-full"
                       isHTML={true}>
                    {pageContent.nftManagerTitle}
                </Title>
            </Section>


            <Section addOnCss="bg-black-olive">
                <Title addOnCss="flex-shrink text-center pb-xl"
                       isHTML={true}>
                    {pageContent.nftTitle}
                </Title>
                <div className="text-dark-vanilla md:w-full
                                        text-left md:text-justify sm:text-justify
                                        md:text-lg
                                        px-2xl md:px-0 pb-xl"
                     dangerouslySetInnerHTML={{"__html": pageContent.nftDescription}}></div>

                <div className="flex gap-x-[80px] gap-y-[40px] flex-wrap justify-center">
                    <NftList nfts={pageContent.nftList}/>
                </div>
            </Section>

            <Section addOnCss="bg-[#020417] py-5xl">
                <Title addOnCss="flex-shrink" isHTML={true}>{pageContent.foundersTitle}</Title>
                <div className="text-dark-vanilla
                                        w-[50%] md:w-full
                                        text-left
                                        md:text-lg
                                        px-2xl md:px-0
                                        pb-5xl md:pb-2xl"
                     dangerouslySetInnerHTML={{"__html": pageContent.foundersDescription}}></div>
                <div>
                    <Carousel items={founders}/>
                </div>
            </Section>
            <div className="-mt-[calc(10vw)] flex">
                <div className="w-1/2">
                    <div className="bg-[url('../public/images/ampersand.png')] bg-cover bg-no-repeat
                                    w-[10vw] h-[calc(10vw*1.5)]
                                    ml-auto mr-[50%]"></div>
                </div>
            </div>

            <Section addOnCss="bg-eerie-black">
                <Title addOnCss="flex-shrink" isHTML={true}>{pageContent.teamTitle}</Title>
                <div className="text-dark-vanilla
                                    w-[50%] md:w-full
                                    text-left md:text-justify sm:text-justify
                                    md:text-lg
                                    px-2xl md:px-0
                                    pb-5xl md:pb-2xl"
                     dangerouslySetInnerHTML={{"__html": pageContent.teamDescription}}
                >
                </div>
                <div>
                    <Carousel items={teammates}/>
                </div>
            </Section>

            <Section addOnCss="pl-0 pr-0 pt-0
                               md:pl-0 md:pr-0 md:pt-0
                               pb-0
                               w-full ">
                <Video src={process.env.REACT_APP_API_PATH + pageContent.interviewVideo?.url}
                       poster={suluImg(pageContent.interviewPoster)}
                       mimeType={pageContent.interviewVideo?.mimeType}
                       autoPlay muted/>
            </Section>

            <Section addOnCss="bg-black-olive">
                <Title addOnCss="flex-shrink text-left text-dark-vanilla pb-4xl md:pb-2xl"
                       isHTML={true}
                >
                    {pageContent.partnersTitle}
                </Title>
                <div className="flex gap-[48px] md:gap-[24px] justify-center flex-wrap">
                    {pageContent.partners?.map((partner, index) => <img className="w-1/8 max-w-[256px] md:w-[30%]"
                                                                        index={index}
                                                                        src={suluImg(partner.image)}
                                                                        alt={partner.name}
                    />)
                    }
                </div>
            </Section>
            <Section addOnCss="bg-black-olive">
                <Title addOnCss="flex-shrink text-center text-7xl md:text-3xl"
                       isHTML={true}
                >{pageContent.seedTitle}</Title>
            </Section>
            {children}</>)}

    </>);
}
