import { useState, useId } from 'react';


function Checkbox({id, index, checkedRadio, setCheckedRadio}){
    return (
        <input type="radio" id={`radio_${id}_${index}`} checked={index === checkedRadio} name={`carousel_${id}`}
           onChange={e => {
               if (e.target.checked) setCheckedRadio(index)
           }}
           className="hidden

    "/>);
}


function CarouselListItem({item, id, index, checkedRadio, setCheckedRadio}) {
    return (
        <li className={`item
            [&.checked]:opacity-100 opacity-50
            [&.checked]:animation-[all_1s_cubic-bezier(0.455,0.03,0.515,0.955)]
            ${checkedRadio === index ? "checked" : ''}`}
            key={index}
        >
            <label htmlFor={`radio_${id}_${index}`}
                   className=" h-[100px] text-white text-left gap-[12px] cursor-pointer flex">
                <div className="basis-[100px] w-[100px] flex-grow-0 flex-shrink-0 h-[100px]">
                    <img src={item.img}
                         className="pointer-events-none w-full h-full object-cover rounded-[50%] object-[0%_0%]"/>
                </div>
                <div className="flex flex-col">
                    <div className="text-white text-xl">{item.label}</div>
                    <div className="text-dark-vanilla text-sm">{item.subLabel}</div>
                </div>
            </label>
        </li>
    );
}

function CarouselItem({ item, id, index, checkedRadio }) {
    return (
        <div className={`content items-start absolute
                justify-start md:justify-start
                w-full
                h-[max(400px,100%)]
                gap-[24px]
                flex
                md:flex-col
                overflow-hidden;
                text-clip;
                [&.checked]:top-0 -top-[max(400px,100%)]
                md:[&.checked]:top-auto md:top-auto
                md:[&.checked]:left-0 md:-left-[100%]
                [&.checked]:opacity-100 opacity-0
                [&.checked]:transition-[all_1s_cubic-bezier(0.455,0.03,0.515,0.955)]
                ${checkedRadio === index ? "checked" : ''}
            `}>
            <div>
                <div className="w-[300px] md:w-full
                    max-h-[300px]
                    h-[28vw] md:h-[100vw]">
                    <img src={item.img}
                         className="pointer-events-none w-full h-full object-cover rounded-[18px] object-[25%_25%]"/>
                </div>
            </div>
            <div className="text-left">
                <h2>{item.label}</h2>
                <div className="text-dark-vanilla text-sm pb-xl">{item.subLabel}</div>
                <p dangerouslySetInnerHTML={{"__html": item.description}}
                   className="text-dark-vanilla"></p>
            </div>
        </div>);
}

function buildCarouselComponents(item, id, index, checkedRadio, setCheckedRadio) {
    return {
        input: <Checkbox id={id} index={index} key={index} checkedRadio={checkedRadio} setCheckedRadio={setCheckedRadio}/>,
        listItem: <CarouselListItem item={item} id={id} index={index} key={index} checkedRadio={checkedRadio} setCheckedRadio={setCheckedRadio} />,
        item: <CarouselItem item={item} id={id} index={index} key={index} checkedRadio={checkedRadio} setCheckedRadio={setCheckedRadio}  />
    }
}

/**
 *
 * @param items [{label: string, subLabel: string, description: string, img: string }]
 * @returns {JSX.Element}
 * @constructor
 */
export default function Carousel({items}) {
    const [checkedRadio, setCheckedRadio] = useState(0);
    const id = useId();
    const carouselListItems = items.map((item, index) => buildCarouselComponents(item, id, index, checkedRadio, setCheckedRadio));
    const carouselData = carouselListItems.reduce((acc, item, index) => {
        acc.inputs.push(item.input);
        acc.listItems.push(item.listItem);
        acc.items.push(item.item);
        return acc;
    }, {inputs: [], listItems: [], items: []});

    return (<div id={`scene-${id}`} className="w-full relative">
        <div id="left-zone" className="w-full
            flex md:flex-col
            gap-[48px] md:gap-0
            items-start md:items-start flex-grow-0 h-full">
            {carouselData.inputs}
            <ul className="flex list-none content-stretch flex-col flex-grow-[1] m-0 p-0 gap-[24px] align-top">
                {carouselData.listItems}
            </ul>
            <div className="flex list-none content-stretch flex-col flex-grow-[1]
                m-0
                p-0 md:py-2xl
                gap-[24px]
                h-[600px]
                w-full relative overflow-hidden overflow-clip">
                {carouselData.items}
            </div>

        </div>
        <div id="middle-border" className="bg-['gray'] h-[75%] flex-grow-1 max-w-[2px]"></div>
        <div id="right-zone" className="h-full flex-grow-3"></div>
    </div>);
}
