import {useState} from "react";

export default function InputText({ name, placeholder, handleChange, onBlur, required = false, size = null, addOnCss = ''}) {
    const [value, setValue] = useState('');

    function onChange(e) {
        setValue(e.target.value);
        handleChange(name, e.target.value);
    }

    return (<div className="w-full relative">
            <input type="text"
                   name={name}
                   required={required}
                   size={size}
                   placeholder={placeholder + (required ? ' * ' : '')}
                   className={`w-full bg-transparent border-solid border-b-2 border-dark-vanilla py-md text-lg ${addOnCss}`}
                   onChange={onChange}
                   value={value}
                   onBlur={onBlur}
            />
            <div className="w-full text-red text-sm text-right absolute hidden italic"></div>
        </div>
    );
};
