function displayFormError(input, message) {
    input.classList.add('border-b-red');
    input.nextSibling.innerHTML = message;
    input.nextSibling.classList.remove('hidden');
}

function hideFormError(input) {
    input.classList.remove('border-b-red');
    input.nextSibling.classList.add('hidden');
}

function isValidEmail(value) {
    const checkEmailValidity = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return checkEmailValidity.test(value);
}

export {
    displayFormError,
    hideFormError,
    isValidEmail
}
