import DOMPurify from 'dompurify';
import HTMLReactParser from "html-react-parser";

export default function Title({level=1, addOnCss, children, isHTML=false}) {
    if(isHTML) {
        children = HTMLReactParser(DOMPurify.sanitize(children));
    }
    let title;
    switch (level) {
        case 2:
            break;

        default:
            title = <h1 className={`uppercase
                         px-2xl md:px-0
                         text-left
                         text-2
                         text-4xl md:text-2xl
                         ${addOnCss}`}>
                {children}
            </h1>;
            break;
    }

    return (<>{title}</>);
}
