import { useRef } from 'react';

// const navItems = [
//     {
//         label: "The Foundation",
//         url: "/",
//         nav: [
//             {
//                 label: "Description",
//                 url: "#description",
//             },
//             {
//                 label: "The team",
//                 url: "#team",
//             },
//             {
//                 label: "Our partners",
//                 url: "#our-partners",
//             },
//             {
//                 label: "Our impact",
//                 url: "#our-impact",
//             }
//         ]
//     },
//     {
//         label: "Projects",
//         url: "/projects",
//     },
//     {
//         label: "The game",
//         url: "/game",
//     },
//     {
//         label: "Contact",
//         url: "/contact",
//     },
// ]

function NavItems({ items = [] }) {
    return items.map((item, index) =>
        <a href={item.url} key={index}
           className="p-xl text-white cursor-pointer inline-block opacity-[70%]"
        >
            {item.title}
        </a>
    )
}


export default function Navbar({navItems}) {
    const subNavItems = [] || navItems.find((item => item.url === window.location.pathname));

    function toggleNav(e) {
        const nav = toggle.current.nextSibling;
        if(nav.classList.contains("hidden"))
            nav.classList.remove("hidden");
        else
            nav.classList.add("hidden");
    }

    const toggle = useRef(null);
    return (
        <>
            <nav className="text-lg fixed z-[10] top-0 hidden md:block">
                <div className="flex relative">
                    <div ref={toggle} className="fixed top-[12px] left-[8px] bg-dark-vanilla rounded-full
                                    w-[50px] h-[50px] p-[10px] z-[1]"
                         onClick={toggleNav}
                    >
                        <div className="bg-[url('../public/images/logo_giving-back.svg')] w-full h-full
                      bg-cover bg-no-repeat p-[10px]"
                             title="Giving back">
                        </div>
                    </div>

                    <div className="hidden">
                        <div className="fixed bg-eerie-black w-full h-full opacity-80" onClick={toggleNav}></div>

                        <div
                            className="fixed w-[90vw] h-full flex flex-col text-left flex-grow bg-dark-vanilla z-[1]">
                            <div
                                className="ml-[8px] mt-[12px] bg-dark-vanilla rounded-full w-[50px] h-[50px] p-[10px]">
                                <div className="bg-[url('../public/images/logo_giving-back.svg')] w-full h-full
                                    bg-cover bg-no-repeat p-[10px]"
                                     title="Giving back"
                                     onClick={toggleNav}
                                >
                                </div>
                            </div>
                            <NavItems items={navItems}></NavItems>
                        </div>
                    </div>

                </div>

                <div className="flex relative hidden">
                    <div className="bg-dark-vanilla brightness-50 absolute w-full h-full"></div>
                    <div className="text-right w-full">
                        <NavItems items={subNavItems.nav}></NavItems>
                    </div>
                </div>
            </nav>
            <nav className="text-lg fixed w-full z-[10] top-0 md:hidden">
                <div className="flex relative">
                    <div className="bg-dark-vanilla brightness-[67%] absolute w-full h-full flex-shrink"></div>
                    <a href="/" className="bg-[url('../public/images/logo_giving-back.svg')]
                     w-[76px] h-[76px]
                     bg-no-repeat bg-center
                     z-[1]" title="Giving back">
                    </a>
                    <div className="text-right flex-grow">
                        <NavItems items={navItems}></NavItems>
                    </div>
                    {/*<div className="flex-shrink z-[1]">*/}
                    {/*    <Searchbar></Searchbar>*/}
                    {/*</div>*/}

                </div>

                <div className="flex relative hidden">
                    <div className="bg-dark-vanilla brightness-50 absolute w-full h-full"></div>
                    <div className="text-right w-full">
                        <NavItems items={subNavItems.nav}></NavItems>
                    </div>
                </div>
            </nav>
        </>

    );
}
