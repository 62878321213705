import InputText from "./inputText";

/**
 * field hidden from sulu
 * @param formDetails
 * @return {*[]}
 */
export default function suluFields(formDetails) {
    let reactFields = [];
    for(let key in formDetails) {
        const field = formDetails[key];
        switch (true) {
            case /^checksum/.test(key):
                reactFields.push(<InputText key={key}
                                            name={field.property_path}
                                            value={field.data}
                                            addOnCss="hidden"
                />);
                break;
            case /^formId/.test(key):
                reactFields.push(<InputText key={key}
                                            name={field.property_path}
                                            value={field.data}
                                            addOnCss="hidden"
                />);
                break;
            case /^formName/.test(key):
                reactFields.push(<InputText key={key}
                                            name={field.property_path}
                                            value={field.data}
                                            addOnCss="hidden"
                />);
                break;
            case /^locale/.test(key):
                reactFields.push(<InputText key={key}
                                            name={field.property_path}
                                            value={field.data}
                                            addOnCss="hidden"
                />);
                break;
            case /^type/.test(key):
                reactFields.push(<InputText key={key}
                                            name={field.property_path}
                                            value={field.data}
                                            addOnCss="hidden"
                />);
                break;
            case /^typeId/.test(key):
                reactFields.push(<InputText key={key}
                                            name={field.property_path}
                                            value={field.data}
                                            addOnCss="hidden"
                />);
                break;
            default:
                console.log(key);
                break;
        }
    }
    return reactFields;
}
