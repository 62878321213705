import { Form } from "../components/form";
import Section from "../components/section";
import Title from "../components/title";
import {getSuluForms} from "../lib/sulu";
import {useEffect, useState} from "react";
import useFirstRender from "../lib/reactExtends";

const API_PAGE_PATH = process.env.REACT_APP_API_PATH + window.location.pathname;

/**
 * ReactComponent Home
 *  contain the definition of the page content
 * @param pageResponse - ReactComponent or list af ReactComponent
 * @param addOnCss - css overload
 * @returns {JSX.Element}
 * @constructor
 */
export default function Contact({ pageResponse, children }) {
    const [pageContent, setPageContent] = useState({});
    const [forms, setForms] = useState([]);
    const isFirstRender = useFirstRender();
    useEffect(() => {
        if (isFirstRender) {
            setPageContent(pageResponse.data?.content);
            setForms(getSuluForms(pageResponse));
        }
        // Will whenever any item in dependency array changes
    }, [isFirstRender]);
    return (<>
        <Section addOnCss="max-w-[900px] mx-auto">
            <Title addOnCss="pl-0 pr-0 pb-2xl">{pageContent.formTitle}</Title>
            <div className="text-dark-vanilla
                                text-left md:text-justify sm:text-justify
                                md:text-lg
                                pb-2xl md:pb-2xl" dangerouslySetInnerHTML={{"__html": pageContent.formDescription}}></div>
            {forms.map((form, index) => <Form form={form} action={API_PAGE_PATH} key={index}></Form>)}
        </Section>
        {children}
    </>);
}
