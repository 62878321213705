import {initReactI18next} from "react-i18next";
import './App.css';
import en_GB from "./translations/en_GB.json"
import i18n from "i18next";
import Footer from "./components/footer";
import Navbar from "./components/navbar";
import {Route, Routes} from "react-router-dom";
import Home from "./pages/home";
import Contact from "./pages/contact";
import {useEffect, useState} from "react";
import useFirstRender from "./lib/reactExtends";
import axios from "axios";
import Route404 from "./pages/route404";
import Default from "./pages/default";
import {getSuluMetaTags} from "./lib/sulu";



i18n.use(initReactI18next)
    .init({
        resources: {
            en: { translation: en_GB }
        },
        lng: "en",
        fallbackLng: "en",
        interpolation: {
            escapeValue: false
        }
    })

function App() {
    const [navItems, setNavItems] = useState([]);
    const [footerItems, setFooterItems] = useState([])
    const [pageResponse, setPageResponse] = useState({});
    const [metaTags, setMetaTags] = useState([]);
    const isFirstRender = useFirstRender();
    useEffect(() => {
        async function fetchNavigation() {
            // nav init
            try {
                const navJson = await axios.get(process.env.REACT_APP_API_PATH + '/api/navigations/main.json');
                setNavItems(navJson?.data?._embedded?.items);
            } catch( error) {
                console.error(error);
            }

            // footer init
            try {
                const navLeftJson = await axios.get(process.env.REACT_APP_API_PATH + '/api/navigations/footer-left.json');
                const navRightJson = await axios.get(process.env.REACT_APP_API_PATH + '/api/navigations/footer-right.json');
                setFooterItems([{
                    label: "The company",
                    nav:  navLeftJson?.data?._embedded?.items
                }, {
                    label: "Technical Office",
                    nav: navRightJson?.data?._embedded?.items
                }]);
            } catch( error) {
                console.error(error.response);
            }
            try {
                const pageResponse = await axios.get(process.env.REACT_APP_API_PATH + window.location.pathname + '.json')
                setPageResponse(pageResponse);
                setMetaTags(getSuluMetaTags(pageResponse.data?.extension.seo))
            } catch( error) {
                console.error(error.response);
            }
        }
        if (isFirstRender) {
            // Will only run on first render
            fetchNavigation();
        }
        // Will whenever any item in dependency array changes
    }, [isFirstRender, pageResponse]);

    function suluRouting() {
        const routes = [];
        // // home route is not present in navItems must be added manually
        // routes.push(<Route path="/" element={<Home content={pageContent} />} />);

        let component;
        if (!pageResponse?.data)
            return (<></>);

        switch(pageResponse.data.template) {
            case "homepage":
                component = <Home pageResponse={pageResponse} />;
                break;
            case "contact":
                component = <Contact pageResponse={pageResponse} />;
                break;
            case "default":
                component = <Default pageResponse={pageResponse} />;
                break;
            default:
                break;
        }
        if(component)
            routes.push(<Route path={pageResponse.data?.content.url} element={component} />);

        return routes;
    }


    return (
        <div className="App">
            {metaTags}
            <Navbar navItems={navItems}/>
            <div className="mt-[76px] md:mt-0">
                    <Routes>
                        {suluRouting()}
                        <Route path='*' element={<Route404 />}></Route>
                    </Routes>
            </div>
            <Footer navItems={footerItems}/>
        </div>
    );
}

export default App;
