import Section from "../components/section";
import Title from "../components/title";

/**
 * ReactComponent Home
 *  contain the definition of the page content
 * @param pageResponse - ReactComponent or list af ReactComponent
 * @param addOnCss - css overload
 * @returns {JSX.Element}
 * @constructor
 */
export default function Default({pageResponse, children }) {
    const pageContent = pageResponse.data.content;
    return (<>
        <Section addOnCss="max-w-[1980px] md:w-full mx-auto">
            <Title addOnCss="pl-0 pr-0 pb-2xl">{pageContent.articleTitle}</Title>
            <div className="text-dark-vanilla
                                text-left md:text-justify sm:text-justify
                                md:text-lg
                                pb-2xl md:pb-2xl" dangerouslySetInnerHTML={{"__html": pageContent.article}}></div>
        </Section>
        {children}
    </>);
}
