import InputText from "./inputText";
import TextArea from "./textArea";
import Tel from "./tel";
import {displayFormError, hideFormError, isValidEmail} from "./formError";

export default function formFields(fields, handleField) {
    let reactFields = [];
    for(let key in fields) {
        const field = fields[key];
        console.log(field)
        switch (true) {
            case /^firstName/.test(key):
                reactFields.push(<InputText key={key}
                                            name={field.property_path}
                                            required={field.required}
                                            size={field.widthNumber}
                                            placeholder={field.attr.placeholder}
                                            handleChange={handleField}
                />);
                break;
            case /^lastName/.test(key):
                reactFields.push(<InputText key={key}
                                            name={field.property_path}
                                            required={field.required}
                                            size={field.widthNumber}
                                            placeholder={field.attr.placeholder}
                                            handleChange={handleField}
                />);
                break;
            case /^textarea/.test(key):
                reactFields.push(<TextArea key={key}
                                           name={field.property_path}
                                           required={field.required}
                                           size={field.widthNumber}
                                           placeholder={field.attr.placeholder}
                                           handleChange={handleField}
                />);
                break;
            case /^text/.test(key):
                console.log(key);
                reactFields.push(<InputText key={key}
                                            name={field.property_path}
                                            required={field.required}
                                            size={field.widthNumber}
                                            placeholder={field.attr.placeholder}
                                            handleChange={handleField}
                />);
                break;
            case /^email/.test(key):
                reactFields.push(<InputText key={key}
                                            name={field.property_path}
                                            required={field.required}
                                            size={field.widthNumber}
                                            placeholder={field.attr.placeholder}
                                            handleChange={handleField}
                                            onBlur={(e) => {
                                                if(e.target.required && e.target.value === "")
                                                    return displayFormError(e.target, "Field required.")
                                                if(isValidEmail(e.target.value))
                                                    return hideFormError(e.target);
                                                return displayFormError(e.target, "Insert a valid email address")
                                            }}
                />);
                break;
            case /^phone/.test(key):
                reactFields.push(<Tel key={key}
                                      name={field.property_path}
                                      required={field.required}
                                      size={field.widthNumber}
                                      placeholder={field.attr.placeholder}
                                      handleChange={handleField}
                />);
                break;
            default:
                console.log(key);
                break;

        }
    }
    return reactFields;
}
