import Section from "../components/section";
import Title from "../components/title";

/**
 * ReactComponent Home
 *  contain the definition of the page content
 * @param content - ReactComponent or list af ReactComponent
 * @param addOnCss - css overload
 * @returns {JSX.Element}
 * @constructor
 */
export default function Route404({ children }) {
    return (<>
        <Section addOnCss="max-w-[900px] mx-auto">
            <Title addOnCss="pl-0 pr-0 pb-2xl">Page Not found</Title>
            <div className="text-dark-vanilla
                                text-left md:text-justify sm:text-justify
                                md:text-lg
                                pb-2xl md:pb-2xl">404</div>
        </Section>
        {children}
    </>);
}
