/**
 *
 * @param {Object} section
 * @param {Integer} index
 * @return {JSX.Element}
 * @constructor
 */
function FooterSection({ section, index }) {
    const borderColor = ( index % 2 === 0) ? "[&>div:nth-child(odd)]:border-dark-vanilla" : "[&>div:nth-child(even)]:border-eerie-black";
    return (
        <div className={`flex flex-col ${borderColor}`} key={index}>
            <div className="leading-[2] border-t-4 pt-sm">{section.label}</div>
            <ul className="px-xl">
                {section.nav.map((sectionItem, itemIndex) => <li className="text-white font-thin leading-[2]" index={itemIndex}>
                    <a href={sectionItem.url}>{sectionItem.title}</a>
                </li>)}
            </ul>
        </div>
    )
}

/**
 *
 * @param {Array} navItems
 * @return {JSX.Element}
 * @constructor
 */
export default function Footer({navItems}) {
    return (
        <>
            <footer className="w-full flex justify-center bg-black-olive text-xl md:text-sm">
                {navItems.map((item, index) => (item.nav && item.nav.length > 0) ? <FooterSection section={item} index={index}/> : '')}
            </footer>

            <div className="flex-shrink w-full text-center px-2xl py-xl bg-black-olive text-white text-xl font-thin">@giving-back.io</div>
        </>
    );
}
