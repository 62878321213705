import axios from "axios";
import Submit from "./submit";
import {useRef, useState} from "react";
import suluFields from "./suluFields";
import formFields from "./formFields";
import {displayFormError, isValidEmail} from "./formError";

function Form({form, action = ''}) {
    const [fields, setFields] = useState({ ...form.formDetails.fields });
    const formRef = useRef(null);

    function handleField(fieldName, value) {
        let newFields = { ...fields };
        newFields[fieldName].data = value;
        setFields(newFields);
    }

    function isValidSuluForm() {
        let isValid = true;
        console.log("console log");
        for(let key in fields) {
            const field = fields[key];
            const input = formRef.current.querySelector(`[name='${key}']`);
            console.log(input)
            console.log(field);
            console.log(field.required && field.data !== null && field.data?.length <= 0);
            // check require field are not empty
            if(field.required && ( field.data === null  || field.data?.length <= 0)) {
                displayFormError(input, "Field required.");
                isValid = false;
            } else {
                // check rules on fields
                switch (true) {
                    case /^email/.test(key):
                        if(isValidEmail(field.value)) {
                            displayFormError(input, "Insert a valid email address");
                            isValid = false;
                        }
                        break;
                    default:
                        break;
                }
            }
        }

        return isValid;
    }

    function onSubmit(e, action) {
        e.preventDefault();
        if(isValidSuluForm()) {
            sendForm(e, action);
        }
        return false;
    }

    async function sendForm(e, action, suluFormId) {
        let res;
        res = axios.post(action, e.target, { headers: {
                'Content-Type': 'application/json'
            }}).then(() => {
            console.log('OK');
            console.log(res);
        })
            .catch(error => {
                console.log('error');
                console.log(error);
            });

        return false;
    }

    return (
        <form className="flex flex-col py-md md:w-full gap-xl" method="POST" id={form.suluFormId} ref={formRef}
              onSubmit={(e) => onSubmit(e, action)}>
            {suluFields(form.formDetails)}
            {formFields(fields, handleField)}
            <Submit addOnCss='max-w-[256px] w-full mx-auto' onClick={isValidSuluForm}>
                {form.formDetails?.submit?.label}
            </Submit>
        </form>
    );
}

export {
    Form
}
