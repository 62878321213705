import {useState} from "react";

export default function TextArea({ name, placeholder, handleChange, required = false, width = 'full'}) {
    const [value, setValue] = useState('');
    width = 'full' ? 'w-full' : width;

    function onChange(e) {
        setValue(e.target.value)
        handleChange(name, e.target.value);
    }

    return (
        <div className="w-full relative">
            <textarea name={name} rows="5" cols="33"
               required={required}
               placeholder={placeholder + (required ? ' * ' : '')}
               className={`bg-transparent border-solid border-b-2 border-dark-vanilla py-md
                          text-lg
                          ${width}`}
               value={value}
               onChange={onChange}>
            </textarea>
            <div className="w-full text-red text-sm text-right absolute hidden italic"></div>
        </div>

    )
        ;
};
