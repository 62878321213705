import {Helmet} from "react-helmet";

/**
 * @method isObjectKey
 * check if a props name describe by a regexp pattern exist
 * @param {Object} obj
 * @param {RegExp} filter
 * @return string || false
 */
function isObjectKey(obj, filter) {
    if(obj === Object(obj) && Object.prototype.toString.call(obj) !== '[object Object]')
        return false;
    const props = Object.getOwnPropertyNames(obj);
    for (let key in props) {
        if(props[key] && filter.test(props[key]))
            return props[key];
    }
    return false;
}

/**
 * @package Sulu
 * @method getSuluForm
 * extract form sulu page request
 * @param {XMLHttpRequestResponseType} response
 * @return {*|*[]}
 */
function getSuluForms(response) {
    const view = response.data?.view;
    const viewProps = Object.getOwnPropertyNames(view);
    return viewProps.reduce((previous, current) => {
        const test = isObjectKey(view[current], new RegExp('dynamic_' + current));
        if (test) {
            return [...previous, { suluFormId: test, formDetails: view[current][test] }];
        }

        return previous;
    }, []);
}

function suluImg(suluImage) {
    const formatUri = suluImage?.formatUri || '';
    const formatPreferredExtension = suluImage?.formatPreferredExtension || 'webp';
    let format = suluImage?.format || 240;
    switch(format) {
        default: format = `${format}x`; break;
    }
    let src = formatUri.replace('{format}', `sulu-${format}`);
    src = src.replace('{extension}', formatPreferredExtension);
    return process.env.REACT_APP_API_PATH + src;
}

function getSuluMetaTags(suluSEO) {
    return (
    <Helmet>
        <title>{suluSEO.title}</title>
        <link rel="canonical" href={suluSEO.canonicalUrl}/>
        <meta name="keywords" content={suluSEO.keywords}/>
        <meta name="description" content={suluSEO.description}/>
    </Helmet>
    );
    // <meta name="author" content="John Smith"/>,
}


export {
    suluImg,
    getSuluForms,
    getSuluMetaTags
}
